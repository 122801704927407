.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
  position: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-calendar__tile--now {
  border: 1px solid #1087ff !important;
  background: none !important;
}

.react-calendar__tile--active {
  background: #1087ff !important;
  color: #fff;
}

.MuiButton-root-751 {
  color: #FFF !important;
}

/* Override  mui-5 static calender css for dashbaord left panel */
.MuiPickerStaticWrapper-root {
  min-width: 270px;
  margin-left: -10px;
}

.MuiPickerStaticWrapper-root .css-epd502 {
  width: 270px;
  margin: 0 auto;
}

.MuiPickerStaticWrapper-root .MuiCalendarPicker-root {
  width: 270px;
  margin: 0 auto;
}

.MuiPickerStaticWrapper-root .css-mvmu1r {
  margin: 0px 0px;
}

.MuiPickerStaticWrapper-root .MuiPickersDay-root {
  width: 39px;
  height: 39px;
  padding: 0;
  margin: 0px 0px;
}

/* Override  mui-5 css for verify insuance popup buttons */
.MuiButton-containedSuccess {
  border-radius: 25px;
}

.MuiButton-outlined {
  border-radius: 25px;
}

/* Override  mui-5 controls disbaled mode css */
.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  color: #121212;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.Mui-error {
  color: #d32f2f;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  color: #121212;
  opacity: 0.7;
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #121212;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled.Mui-error {
  color: #d32f2f;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.Mui-error {
  color: #d32f2f;
  opacity: 1;
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-error {
  opacity: 1;
  -webkit-text-fill-color: #d32f2f;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: #121212;
}

.MuiButtonBase-root.MuiChip-root.Mui-disabled {
  opacity: 1;
}

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: #121212;
}

#adminFileUpload::file-selector-button {
  font-weight: bold;
  color: white;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  background-color: #1565c0;
}

.sectionGroup:has(.emptyField) {
  display: none;
}
.multiSelectContainer input, li{  
  color: "#616161";
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}
.searchWrapper {
   border: none !important;
}
.multiSelectContainer li:hover {
  background: #F5F5F5 !important;
  color: #000000DD   !important;
}
.multiSelectContainer .chip {
  /* background: #1976d2 !important; */
  background: transparent !important;
 border-radius: 16px;
 font-family: "Roboto","Helvetica","Arial",sans-serif;
 border: 1px solid #bdbdbd;
 color: #000000DD;
}
 .multiSelectContainer * {
  padding-left: 2px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d32f2f;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #d32f2f;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #d32f2f;
}


/* Printable section */
@media print , screen {
  table.report-container {
    page-break-after: always;
    width: 100%;
  }

  thead.report-header {
    display: table-header-group;
    padding: 5px;
  }

  thead.report-header .report-header-cell {
    border-bottom: 1px solid #CCC;
}

.report-content .report-content-cell {
  padding-top: 5px;
}
  tfoot.report-footer {
    display: table-footer-group;
    font-weight: bold;
    text-align: center;
    padding: 0 5px;
  }
}

/* EHRSummary CSS */
#EMRSummary strong {
  text-decoration: underline
}

#EMRSummary h3 {
  margin-bottom: 5px
}

#EMRSummary p {
  margin: 0
}

@media all {
  .container {
    margin-bottom: 5px;
    padding: 5px 0;
    font-weight: normal;
  }
  
  .container * {
    font-size: medium;
    -webkit-print-color-adjust: exact;
  }
  .container [class*='col-'] {
    vertical-align: top;
    float: left;
  }
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .pull-right{float:right !important}
  .pull-left{float:left !important}
  .text-right{text-align:right !important}
  .text-left{text-align:left !important}

  .logo {max-width: 300px; padding: 1px;}
  .text-grey{color:#5A5A5A}
  .report-header{font-weight: normal; color: grey; text-decoration: none;}
}

.loginBannerImage {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 60% !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-image: url("./res/images/medtel_banner.png") !important;
}

.loginBannerContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.patientInfo {
  margin-left:10px;
  width:250px;
  padding-top:5px;
  padding-bottom:5px;
}

  @media (max-width: 824px) {
    .patientInfo {
      padding-top:35px;
    }}

  @media only screen and (max-width: 698px) {
    .patientInfo {
      padding-top:45px;
    }}

    @media only screen and (max-width: 662px) {
      .patientInfo {
        padding-top:60px;
      }}

      @media (max-width: 1204px) and (min-width: 824px) {
        .patientInfo {
          padding-top:15px;
        }}